<template>
  <DefaultTemplate>
    <div class="main-slot text-primary pd-5">
      <h3 class="text-center text-primary">
        EXAC เติมเต็มความเป็นเลิศส่งออกไทย <br />เติบโตไกลอย่างยั่งยืน
      </h3>
      <div class="text-center text-grey text-primary mg-t-1">
        ขออภัย ไม่พบหน้าดังกล่าว<br />
        <router-link to="/" class="text-white not-hover"
          ><u>กลับสู่หน้าหลัก</u></router-link
        >
      </div>
    </div>
  </DefaultTemplate>
</template>
<script>
import DefaultTemplate from "@/template/DefaultTemplate";
import { HTTP } from "@/service/axios";
// import pdf from "../../%e0%b9%82%e0%b8%84%e0%b8%a3%e0%b8%87%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b8%aa%e0%b9%88%e0%b8%87%e0%b9%80%e0%b8%aa%e0%b8%a3%e0%b8%b4%e0%b8%a1%e0%b8%9c%e0%b8%b9%e0%b9%89%e0%b8%9b%e0%b8%a3%e0%b8%b0%e0%b8%81%e0%b8%ad%e0%b8%9a%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b8%aa%e0%b9%88%e0%b8%87%e0%b8%ad%e0%b8%ad%e0%b8%81.pdf";
export default {
  components: {
    DefaultTemplate
  },
  data() {
    return {
      pdfLink: require("@/assets/โครงการส่งเริมผู้ประกอบการส่งออก.pdf")
    };
  },
  //   methods: {
  //     forceFileDownload(response) {
  //       console.log("respo", response);
  //       const url = window.URL.createObjectURL(
  //         new Blob([response.data], { type: "application/pdf" })
  //       );
  //       //   const link = document.createElement("a");
  //       //   link.href = url;
  //       //   //   link.setAttribute("download", response); //or any other extension
  //       //   document.body.appendChild(link);
  //       //   link.click();

  //       // const url = URL.createObjectURL(file);
  //       //Open the URL on new Window
  //       window.open(url);
  //     },
  //   },
  mounted() {
    //console.log("to", this.$router.history.current.path);
    if (
      this.$router.history.current.path ==
        "/โครงการส่งเสริมผู้ประกอบการส่งออก.pdf" ||
      this.$router.history.current.path ==
        "/%e0%b9%82%e0%b8%84%e0%b8%a3%e0%b8%87%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b8%aa%e0%b9%88%e0%b8%87%e0%b9%80%e0%b8%aa%e0%b8%a3%e0%b8%b4%e0%b8%a1%e0%b8%9c%e0%b8%b9%e0%b9%89%e0%b8%9b%e0%b8%a3%e0%b8%b0%e0%b8%81%e0%b8%ad%e0%b8%9a%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b8%aa%e0%b9%88%e0%b8%87%e0%b8%ad%e0%b8%ad%e0%b8%81.pdf"
    ) {
      //   HTTP.get(
      //     "https://terak.lffintech.co.th/%e0%b9%82%e0%b8%84%e0%b8%a3%e0%b8%87%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b8%aa%e0%b9%88%e0%b8%87%e0%b9%80%e0%b8%aa%e0%b8%a3%e0%b8%b4%e0%b8%a1%e0%b8%9c%e0%b8%b9%e0%b9%89%e0%b8%9b%e0%b8%a3%e0%b8%b0%e0%b8%81%e0%b8%ad%e0%b8%9a%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b8%aa%e0%b9%88%e0%b8%87%e0%b8%ad%e0%b8%ad%e0%b8%81.pdf",
      //     {
      //       method: "GET",
      //       responseType: "blob",
      //     }
      //   ).then((response) => {
      //     const file = new Blob([response.data], { type: "application/pdf" });
      //     //Build a URL from the file
      //     const fileURL = URL.createObjectURL(file);
      //     //Open the URL on new Window
      //     window.open(fileURL);
      //     setTimeout(() => {
      //       this.$router.push({ path: "/" });
      //     }, 250);
      //   });
      window.location = this.pdfLink;
    }
  }
};
</script>

<style scoped>
.main-slot {
  min-height: calc(100vh - 177px);
}
@media (max-width: 767px) {
  .main-slot {
    min-height: calc(100vh - 175px);
  }
}
@media (min-width: 1300px) {
  .main-slot {
    min-height: calc(100vh - 176px);
  }
}
/* @media (min-width: 1920px) {
  .main-slot {
    min-height: calc(100vh - 216px);
  }
} */
</style>